/**
 * To deploy: Transmit.  Oases S3 oases-hub/tml
 * copy Sites/archive/tml-video/build to oases-hub/tml
 */

import { Fragment, useState } from "react";
import ModalVideo from "react-modal-video";
import "../node_modules/react-modal-video/scss/modal-video.scss";

const knownURLs = [
	{
		company: "portal.tutormeeducation",
		comptla: "tml",
		compname: "Tutor Me Education",
		favicon: "tml.png",
		config: "26e37e741dd4774b26d8500c7ee468c2",
		customer: "08f7c3e4501d1b009ff87d93dfa43268",
		conf: "n4LJIy",
		vidURL: "./intro.mp4",
		srcold: `https://secure.oasesonline.com/client/oas-loadmp.js`,
		src: "https://mp.oasesonline.com/oas-loadmp3.js",
	},
	{
		company: "lausd.tutormeeducation",
		comptla: "tml",
		compname: "Tutor Me Education",
		favicon: "tml.png",
		config: "65981794f96963ca2766ff1050f7a001",
		customer: "08f7c3e4501d1b009ff87d93dfa43268",
		conf: "4pb6Fb",
		srcold: `https://secure.oasesonline.com/client/oas-loadmp.js`,
		src: "https://mp.oasesonline.com/oas-loadmp3.js",
		entry: "login",
	},
	{
		company: "localhost", // portal
		comptla: "tml",
		compname: "Tutor Me Education",
		favicon: "tml.png",
		config: "26e37e741dd4774b26d8500c7ee468c2",
		customer: "08f7c3e4501d1b009ff87d93dfa43268",
		conf: "n4LJIy",
		vidURL: "./intro.mp4",
		srcold: `https://secure.oasesonline.com/client/oas-loadmp.js`,
		src: "https://localhost.oasesonline.com/mp3/build/oas-loadmp3.js",
		srctmp: "https://mp-panther.oasesonline.com/oas-loadmp3.js",
	},
	{
		company: "localXhost", // lausd
		comptla: "tml",
		compname: "Tutor Me Education",
		favicon: "tml.png",
		config: "65981794f96963ca2766ff1050f7a001",
		customer: "08f7c3e4501d1b009ff87d93dfa43268",
		conf: "4pb6Fb", // lausd
		srcold: `https://secure.oasesonline.com/client/oas-loadmp.js`,
		srctmp: "https://localhost.oasesonline.com:3006/mp3/build/oas-loadmp3.js",
		src: "https://mp-panther.oasesonline.com/oas-loadmp3.js",
	},
];

function App() {
	const [isOpen, setOpen] = useState(false);

	const isURLKnown = knownURLs.find((f) => window.location.href.includes(f.company));

	if (isURLKnown) {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.id = "oas-app";
		// script.setAttribute("config", isURLKnown.config);
		// script.setAttribute("customer", isURLKnown.customer);
		script.setAttribute("conf", isURLKnown.conf);
		isURLKnown.entry && script.setAttribute("entry", isURLKnown.entry);
		script.src = isURLKnown.src;
		document.head.append(script);
	}

	return isURLKnown ? (
		<Fragment>
			{isURLKnown?.vidURL && (
				<div className="App">
					<ModalVideo
						channel="custom"
						autoplay
						isOpen={isOpen}
						url={isURLKnown?.vidURL}
						onClose={() => setOpen(false)}
					/>

					<button className="btn vidbutton" onClick={() => setOpen(true)}>
						Intro Video
					</button>
				</div>
			)}
		</Fragment>
	) : (
		<div className="mt-5 text-danger text-center">
			<b>Sorry, invalid Configuration.</b>
		</div>
	);
}

export default App;
